export const validateEmail = (email) => {
  const isValid = email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  let errorMessage = "";
  if (!isValid) errorMessage = "Invalid email address.";
  if (!email) errorMessage = "Email address is required.";

  return { isValid, errorMessage };
};

export const validatePassword = (password) => {
  const isValid = password.length > 6 && password !== "";

  let errorMessage = "";
  if (password.length < 6)
    errorMessage = "Password should be more than 6 characters.";
  if (!password) errorMessage = "Password is required.";

  return { isValid, errorMessage };
};

export const capitalizeFirstLetter = (string = "") => {
  string = string.replace(/-/g, " ");
  if (string !== "") return string.charAt(0).toUpperCase() + string.slice(1);

  //return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hypenated = (string = "") => {
  string = string.replace(/\s/g, "-");
  return string.toLowerCase();
};
