import React, { Suspense, useEffect, useState } from "react";
import Layout from "./components/layout/Layout";
import { Route, Switch, useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import { getSiteConfig } from "./store/authMethods";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

library.add(fas, far, fab);

const Home = React.lazy(() => import("./pages/Home"));
const StudentHome = React.lazy(() => import("./pages/student/Home"));
const StaffHome = React.lazy(() => import("./pages/staff/Home"));

/* localStorage.removeItem("token");
localStorage.removeItem("user-name");
localStorage.removeItem("user-info");
localStorage.setItem("csrf-token", ''); */

/* localStorage.setItem("token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMWNlZDA0NzJjZjI5ZjA1MmExMDdiN2Y1YTgyMzVjYTBjNmJjNmQ4MWExNWRmNjc3YWY4MjQxMmJlZWNlMDU2ZjA5YjVhOTNlNjQ5ZWJhZDgiLCJpYXQiOjE2OTU4MDA3MDYuNjU4MzY1LCJuYmYiOjE2OTU4MDA3MDYuNjU4MzY2LCJleHAiOjE2OTU4ODcxMDYuNjUzMDk5LCJzdWIiOiIxMCIsInNjb3BlcyI6W119.KQ-3dhTUB-dpnjcDzYkLUQMkpmVxZC2g4ZoLg8gdItmdIDHApSQA14plZdGMCQPLDA0GIRjnXol3HsacgEUWJ2wVPXRUKfmYz5WLwJtcEn6p75-Xe_nxQ6Ye5DkI5TTGAgN--VsCIMqZUyHWvTFULwwkMKGvwSfh_K6mly9GWmn6cCEExekcQyijkNUWiqIZoVoGHwtlyyZ9WVULAzrZLiASLA2bsSSQRVjYHIhc13Ms9hUQfMeBKmBnXXkp5gRYN7HrszgQKSMZEGWKgUDlYHozV_OGoeh5sDxHSCM5E1JZWmGo7lygwjhYv-mwZy0WezAmN0abcYY_Opc_bCT4bHtoRPhubWam4tvfjBuGZblrMvC8Y_NlfI0F7TbL_neG-bR9ur8ghnBt8cz5WfVa7Xi30kqe5NqR9LLDpueUdD4nZFpWsweDYICTP5ahdk4B4XEqc6ZiB8rPWejKhpMmuRsYqgWbB_D8C6JN7pW1kmHv4IadpxVov05SMunrOR-tQIhTZRnwtkdhrOQ_6NnI8kMdAfayo6lVrt9IYQZ1KnlaKbgRxoLs0MJyPPbX6RFSCydHjRo5s1MKCWg8-aAxDWgqH89-oOFQ2CkzmYz7HMgcdHf7JEd5G_gOEMSO9x2dkA3uUEPBAEhYfXVioITUzgUKqIJruhsDx94L7WD5_qk");
localStorage.setItem("user-name", "UG Student");
localStorage.setItem("user-info", '{"id":10,"name":"UG Student","lastname":"Test","email":"testuser@uowd.com","email_verified_at":null,"accessToken":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjVkNTYyNTI1NGNkMTEzMTgwNzViMjE2MDQxYmU4NTJjNzAyZDg5ZmU5ZDgyM2VjNWJjMDFkZTUxNTg1OGRkMDQ0NDhmMGUxNzc0ZjZhMTMiLCJpYXQiOjE2OTUwMzM2MjEuNDk3OTA3LCJuYmYiOjE2OTUwMzM2MjEuNDk3OTE4LCJleHAiOjE2OTUxMjAwMjEuNDkzMTk5LCJzdWIiOiIxMCIsInNjb3BlcyI6W119.KkWp9PZIY35nadRXLcI43acyCyX_dDIATxyzDRGWv_ypdqetRYIvZVo2GuLqDSlorkPQLaXyWx7BxpalEQzie0aHhHFKOihAsnSsDsdc-2pxw765vzzth6UjTDKbPS6_Bl8xBhC9tIheeTrDRjMwi1WKS-dPIB3NAy27C3c_5KX8gOpLKatuSzmg7E0q7bqGy_pHx9_V2uAY-LbMxEf1ORDEnJ75KoWB5sIOFZ9MFPueM3-ZxlMcdvyr-jt45i65bK_m6Ifdj4_utw7tLm5op8_UjSv-xY0IhOZm5sEo9FeqR0nHafff4AsC3lLSk_4gve5hr_8fGQMUBM6dfg-ZNyQwRps7dyMeFFQICrXmFOjfEtDkgTGW9-L7bXWCfpeTN6OUKdnUVc3cWSc3xn5co6D-AzQXHXoSESESog-iIt-Jo_MQL8cnEE-Z_VxuX3AqjPgfQAQrEyMmDI8T4ZyHXmRNdGqKCitvMsx-g75zx0PcS4FxIcN2xRQl06h5YM2NoKtUpuaJe9Y4H1sfsbqWIsx173pEKMpulEQlla5Z_3ARWjl1xXH_mBJDrnkHZWOn9d00B8uXObQLQfuqXDajTPgpTJX16zVgbjm8pV60ou3vsPWhmehHtXR3u43ghJoAz2ZD0YO1RSpy52T3GgQ9YA1ajr63OC0PZ7czUjs6kbY","created_at":"2022-04-27T06:30:53.000000Z","updated_at":"2023-09-18T10:40:21.000000Z","roleid":null,"type":"student","phone":null,"avatar":"","updated_by":0,"status":"Y","lastlogin":"2023-09-18 10:40:21","role":null}');
localStorage.removeItem("csrf-token"); */

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isGAReady, setIsGAReady] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getSiteConfig()).then((data) => {
      ReactGA.initialize(data.google_analytic_tracking_id);
      setKeywords(data.site_keywords);
      setDescription(data.site_description);
      setIsGAReady(true);
    });
  }, []);

  useEffect(() => {
    if (isGAReady) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location.pathname, location.search, isGAReady]);

  return (
    <Layout>
      {/* {isGAReady && (
        <Helmet>
          <meta name="keywords" content={keywords} />
          <meta name="description" content={description} />
        </Helmet>
      )} */}
      {/* <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/forgot-password" component={Home} />
        <Route path="/student" exact component={StudentHome} />
        <Route path="/staff" exact component={StaffHome} />
      </Switch>
      </Suspense> */}
    </Layout>
  );
}

export default App;
