import React from "react";
import { OverlayTrigger,Tooltip } from "react-bootstrap";

function CustomTooltip(props) {
  return (
    <OverlayTrigger overlay={<Tooltip>{props.title}</Tooltip>}>
      {props.children}
    </OverlayTrigger>
  );
}

export default CustomTooltip;
