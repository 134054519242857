import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EventItem({ additionalProps="", data }) {
  const showThumbnail=additionalProps.showThumbnail;
  const showDetails=additionalProps.showDetails;
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date_thumb = new Date(data.event_date).toLocaleDateString(
    "en-US",
    options
  );

  const borderBottom=!showDetails && 'border-bottom';

  return (
    <div className={`mb-3 pb-3 position-relative ${borderBottom}`}>
      <Row>
        {showThumbnail && (
          <Col xs={4} sm={3} md={2}>
            <div className="ratio ratio-1x1 bg-gray mb-3 mb-sm-0">
              <div className="d-flex">
                <strong className="m-auto bold text-center">{date_thumb}</strong>
              </div>
            </div>
          </Col>
        )}
        <Col className="d-flex flex-column">
          {!showDetails && (
            <h5 className={`mt-0 mb-2 bold`}>{data.name}</h5>
          )}
          <div className="mb-2">
            <p className="mb-1 mt-0 text-muted">
              {data.location && (
                <span className="me-3">
                  <FontAwesomeIcon icon="map-marker-alt" />{" "}
                  {data.location}
                </span>
              )}
              {data.event_date && (
                <span className="me-3">
                  <FontAwesomeIcon icon="calendar-alt" />{" "}
                  {new Date(data.event_date).toLocaleDateString("en-US",options)}{" "}
                  {data.event_end_date !== data.event_date &&
                    "to " + new Date(data.event_end_date).toLocaleString("en-US",options)
                    }
                </span>
              )}
              {data.start_time && (
                <span className="me-3">
                  <FontAwesomeIcon icon="clock" /> {data.start_time} to{" "}
                  {data.end_time}
                </span>
              )}
            </p>
            <p className="mb-1 mt-0 text-muted">
              {data.presenter && (
                <span className="me-3">
                  <FontAwesomeIcon icon="chalkboard-teacher" />{" "}
                  {data.presenter}
                </span>
              )}
              {data.open_to && (
                <span className="me-3">
                  <FontAwesomeIcon icon="users" /> {data.open_to}
                </span>
              )}
            </p>
          </div>

          <div
            className={showDetails ? "my-3" : "mb-3"}
            dangerouslySetInnerHTML={{
              __html: showDetails
                ? data.details
                : data.details.length > 100 &&
                  data.details.substring(0, 100) + "...",
            }}
          />

          {!showDetails && (
            <div className="mt-auto d-inline-flex">
              <Link
                to={`/events/${data.slug}`}
                className="btn btn-danger btn-sm"
              >
                View Details
              </Link>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default EventItem;
