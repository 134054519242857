import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function BannerSliderItem({
  bannerImage,
  title,
  contents,
  ctaLabel,
  ctaLink,
  type,
  videoUrl
}) {

  const bgImage=(type === "video") ? {} : { backgroundImage : `url(${bannerImage})` };
  
  return (
    <div
      className="slideElement"
      style={bgImage}
    >
      {type === "video" ? (
        <div style={{ zIndex: "-99" }} className="ratio ratio-21x9">
          <iframe
            frameBorder="0"
            height="100%"
            width="100%"
            src={`https://youtube.com/embed/${videoUrl}?autoplay=1&controls=0&showinfo=0&autohide=1&loop=1&mute=1&rel=0`}
            title={title}
          ></iframe>
        </div>
      ) : (
        <img src={bannerImage} className="d-block d-md-none img-fluid" alt="" />
      )}

      {(title || contents) && (
        <Fragment>
          <aside>
            <div className="container text-center text-md-start">
              <h1
                className="bold mb-2"
                dangerouslySetInnerHTML={{ __html: title }}
              ></h1>
              <div />
              <p
                className="lead"
                dangerouslySetInnerHTML={{ __html: 
                  contents.length > 50 ? contents.substring(0, 150) + "..." : contents
                }}
              />
              {ctaLabel && ctaLink && (
                <Link to={ctaLink} className="btn btn-danger">
                  {ctaLabel}
                </Link>
              )}
            </div>
          </aside>
          <div className="bgOverlay d-none d-md-block" />
        </Fragment>
      )}
    </div>
  );
}

export default BannerSliderItem;
