import React, { Fragment, useEffect, useMemo, useRef } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BannerSlider } from "../../components/ui/slider/SlickSlider";

import {
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Breadcrumbs from "../../components/ui/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { publicActions } from "../../store/publicSlice";
import { SEARCH_RESULTS } from "../../config/api-urls";
import useHttp from "../../hooks/use-http";
import CommonAPI from "../../lib/common";
import LoadingSpinner, {PageLoader} from "../../components/ui/LoadingSpinner";
import CustomPagination from "../../components/ui/CustomPagination";
import SearchResultItem from "../../components/ui/SearchResultItem";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

function SearchResults() {
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();
  const keywordInputRef = useRef();
  const history = useHistory();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    dispatch(publicActions.toggleHamburger(false));
    window.scrollTo(0, 0);
  }, [dispatch]);

  const {
    sendRequest,
    status,
    data: loadedResults,
    error,
  } = useHttp(CommonAPI, true);

  const url_params = queryParams.toString();

  useEffect(() => {
    sendRequest(SEARCH_RESULTS + "?" + url_params);
  }, [sendRequest, url_params]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const enteredKeyword = keywordInputRef.current.value;

    sendRequest(SEARCH_RESULTS + `?s=${enteredKeyword}`);
    history.push(`${match.url}?s=${enteredKeyword}`);
  };
  return (
    <Fragment>
      <BannerSlider />
      <Breadcrumbs />
      <section
        className="bgCover"
      >
        <Container className="content mb-5">
          <Row className="align-items-end pb-3 g-0 border-bottom mb-3">
            <Col md={8} className="d-flex align-items-center mt-5">
              <h2 className="m-0 mr-3 bold">Search results <span className="regular">for keyword <i className="text-danger">{queryParams.get('s')}</i></span></h2>
            </Col>
            {/* <Col md={4}>
              <Form onSubmit={formSubmitHandler} className={`mt-2 mt-md-0`}>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <FloatingLabel label="Enter your keyword">
                      <Form.Control
                        type="text"
                        placeholder="Enter your keyword"
                        ref={keywordInputRef}
                      />
                    </FloatingLabel>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      size="lg"
                      variant="danger"
                      style={{ minWidth: "unset" }}
                    >
                      <FontAwesomeIcon icon="search" />
                    </Button>
                  </div>
                </div>
              </Form>
            </Col> */}
          </Row>

          {status === "pending" && <LoadingSpinner />}
          {error && <p>{error.message}</p>}
          {status === "completed" && !error && loadedResults.data.length > 0 && (
            <CustomPagination
              data={loadedResults.data}
              RenderComponent={SearchResultItem}
              pageLimit={5}
              dataLimit={loadedResults.per_page}
              total={loadedResults.total}
            />
          )}

          {status === "completed" && !error && loadedResults.data.length < 1 && (
            <p>No records found.</p>
          )}
        </Container>
      </section>
    </Fragment>
  );
}

export default SearchResults;
