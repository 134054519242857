import React from "react";

function HomeGallerySliderItem(props) {
  return (
    <div className="ratio ratio-4x3" data-fancybox="gallery" data-type={props.type} role="button" data-caption={props.caption} data-src={props.url}>
      <div
        className="bg-cover-absolute"
        style={{
          backgroundImage: `url('${props.url}')`,
        }}
      />
      <div className="bgOverlay" />
    </div>
  );
}

export default HomeGallerySliderItem;
