import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isNavExpanded: false
};

const publicSlice = createSlice({
  name: "public",
  initialState: initialState,
  reducers: {
    toggleHamburger(state, action) {
        state.isNavExpanded = action.payload;
    },
  },
});

export default publicSlice.reducer;
export const publicActions = publicSlice.actions;
