import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helper";
import classes from "./Breadcrumbs.module.css";

function Breadcrumbs({ pageTitle, type, links }) {
  const match = useRouteMatch();
  const mmm = match.url;
  const splitted_value = mmm.split("/");

  const lastIndex = splitted_value.length - 1;
  const current_links = splitted_value.splice(1, lastIndex);

  let active_link = "/";
  const constructed_links = [];

  if (type !== "page") {
    current_links.map((current_link, i) => {
      active_link += current_link;
      active_link += i !== current_links.length - 2 ? "/" : "";

      if (current_link !== "page")
        constructed_links.push({ name: current_link, link: active_link });

      return constructed_links;
    });
  } else {
    links.map((link, i) =>
      constructed_links.push({ name: link.title, link: link.url })
    );
  }

  return (
    <section className={classes.breadCrumbs}>
      <div className="container">
        <ul className="list-inline mb-0">
          <li className="list-inline-item">
            <Link to={`/`}>Home</Link>
          </li>

          {type !== "page" ? (
            constructed_links.map((link, i) =>
              i + 1 !== constructed_links.length ? (
                <li className="list-inline-item" key={`${link.link} ${i}`}>
                  <Link to={link.link}>{capitalizeFirstLetter(link.name)}</Link>
                </li>
              ) : pageTitle ? (
                <li className="list-inline-item" key={`${link.link} ${i}`}>
                  {pageTitle}
                </li>
              ) : (
                <li className="list-inline-item" key={`${link.link} ${i}`}>
                  {capitalizeFirstLetter(link.name)}
                </li>
              )
            )
          ) : (
            <>
              {constructed_links.map(
                (link, i) =>
                  i !== constructed_links.length && link.name && (
                    <li className="list-inline-item" key={`${link.link} ${i}`}>
                      <Link to={link.link}>
                        {capitalizeFirstLetter(link.name)}
                      </Link>
                    </li>
                  )
              )}
              <li className="list-inline-item" key={`pageTitle`}>
                {pageTitle}
              </li>
            </>
          )}
        </ul>
      </div>
    </section>
  );
}

export default Breadcrumbs;
