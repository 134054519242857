import React, { Suspense, Fragment, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { SAML_LOGOUT_URL } from "../../config/constants";
import Events from "../../pages/common/Events";
import SearchResults from "../../pages/common/SearchResults";
import { authActions } from "../../store/authSlice";
import { publicActions } from "../../store/publicSlice";
import LoadingSpinner from "../ui/LoadingSpinner";

const MainNavigation = React.lazy(() => import("./MainNavigation"));
const StaffNavigation = React.lazy(() => import("./StaffNavigation"));
const StudentNavigation = React.lazy(() => import("./StudentNavigation"));
const PreFooter = React.lazy(() => import("./PreFooter"));
const BottomFooter = React.lazy(() => import("./BottomFooter"));
const Service = React.lazy(() => import("../../pages/common/Service"));
const DynamicForm = React.lazy(() => import("../../pages/common/DynamicForm"));
const Page = React.lazy(() => import("../../pages/common/Page"));
const DocumentsAndForms = React.lazy(() =>
  import("../../pages/common/DocumentsAndForms")
);
const Committees = React.lazy(() => import("../../pages/common/Committees"));

const CommitteesDetailPage = React.lazy(() =>
  import("../../pages/common/CommitteesDetailPage")
);

const ServiceDetailPage = React.lazy(() =>
  import("../../pages/common/ServiceDetailPage")
);
const NoticeDetails = React.lazy(() =>
  import("../../pages/common/NoticeDetails")
);
const EventDetails = React.lazy(() =>
  import("../../pages/common/EventDetails")
);
const PageNotFound = React.lazy(() =>
  import("../../pages/common/PageNotFound")
);
const Notices = React.lazy(() => import("../../pages/common/Notices"));
const StaffDirectory = React.lazy(() =>
  import("../../pages/common/StaffDirectory")
);
const Profile = React.lazy(() => import("../../pages/common/Profile"));

const Home = React.lazy(() => import("../../pages/Home"));
const StudentHome = React.lazy(() => import("../../pages/student/Home"));
const StaffHome = React.lazy(() => import("../../pages/staff/Home"));

function Layout(props) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isNavExpanded = useSelector((state) => state.public.isNavExpanded);
  const redirectModule = useSelector((state) => state.auth.redirectModule);
  
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/page-not-found")
      if (!isAuthenticated) {
        localStorage.setItem("redirect", location.pathname);
      }
  }, [location]);

  useEffect(() => {
    const reLink = localStorage.getItem("redirect");
    if (isAuthenticated && reLink) {
      localStorage.removeItem("redirect");
      history.replace(reLink);
    }
  }, [isAuthenticated]);

  const logoutHandler = () => {
    dispatch(authActions.logout());
    window.location = SAML_LOGOUT_URL;
  };

  const toggleHamburgerHandler = () => {
    dispatch(publicActions.toggleHamburger(!isNavExpanded));
  };

  return (
    <Fragment>
      <div className="main flex-shrink-0">
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            {isAuthenticated ? (
              <Fragment>
                {redirectModule === "student" && (
                  <Route path="/">
                    <StudentNavigation
                      onLogout={logoutHandler}
                      onToggleHamburger={toggleHamburgerHandler}
                    />
                  </Route>
                )}
                {redirectModule === "staff" && (
                  <Route path="/">
                    <StaffNavigation
                      onLogout={logoutHandler}
                      onToggleHamburger={toggleHamburgerHandler}
                    />
                  </Route>
                )}

                <Route path={`/services`} exact>
                  <Redirect to={`/`} />
                </Route>

                <Switch>
                  <Route path={`/search-results`} exact>
                    <SearchResults />
                  </Route>

                  <Route path={`/profile`} exact>
                    <Profile />
                  </Route>

                  <Route path={`/notices`} exact>
                    <Notices />
                  </Route>
                  <Route path={`/notices/:id`}>
                    <NoticeDetails />
                  </Route>
                  <Route path={`/events`} exact>
                    <Events />
                  </Route>
                  <Route path={`/events/:slug`}>
                    <EventDetails />
                  </Route>

                  <Route path={`/services/:servicename/:slug`}>
                    <ServiceDetailPage />
                  </Route>
                  {/* <Route path={`/services/library`} exact>
                    <Library />
                  </Route> */}
                  <Route path={`/services/:servicename`} exact>
                    <Service />
                  </Route>
                  <Route path={`/documents-and-forms`} exact>
                    <DocumentsAndForms />
                  </Route>
                  <Route path={`/staff-directory`} exact>
                    <StaffDirectory />
                  </Route>
                  <Route path={`/committees`} exact>
                    <Committees />
                  </Route>
                  <Route path={`/committees/:committee`}>
                    <CommitteesDetailPage />
                  </Route>
                  <Route path={`/page/:slug`}>
                    <Page />
                  </Route>
                  <Route path={`/form/:slug`} exact>
                    <DynamicForm />
                  </Route>

                  {redirectModule === "student" && (
                    <Route path="/" exact component={StudentHome} />
                  )}
                  {redirectModule === "staff" && (
                    <Route path="/" exact component={StaffHome} />
                  )}

                  <Route path={["/page-not-found", "*"]} exact>
                    <PageNotFound />
                  </Route>
                </Switch>
              </Fragment>
            ) : (
              <Fragment>
                <MainNavigation />
                <Switch>
                  <Route path={`/page/:slug`}>
                    <Page />
                  </Route>
                  <Route path="/forgot-password" component={Home} />
                  <Route path={`/`} exact>
                    <Home />
                  </Route>
                  <Route path={["/page-not-found"]} exact>
                    <PageNotFound />
                  </Route>
                  <Route path={["*"]} exact>
                    <Redirect to={`/`} />
                  </Route>
                </Switch>
              </Fragment>
            )}
          </Switch>

          {props.children}
        </Suspense>
      </div>
      <Suspense fallback={<LoadingSpinner />}>
        <footer className="mt-auto">
          <Route path="/" exact>
            <PreFooter />
          </Route>

          <BottomFooter />
        </footer>
      </Suspense>
    </Fragment>
  );
}

export default Layout;
