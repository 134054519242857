import API from "./api";

const CommonAPI = async (API_URL, API_PARAMS = {}, REQUEST_TYPE = "GET") => {
  let response = "";

  switch (REQUEST_TYPE) {
    case "POST":
      response = await API.post(API_URL, API_PARAMS);
      return response.data;
    default:
      response = await API.get(API_URL, { params: API_PARAMS });
      const string = "^services/";
      const regexp = new RegExp(string);

      if (regexp.test(API_URL)) {
        return await response.data;
      }
      return await response.data.data;
  }
};

export default CommonAPI;
