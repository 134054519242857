import React from "react";
import Slider from "react-slick";
import "./SlickSlider.css"; // this is t be used for global slider
import classes from "./NewsTicker.module.css";
// import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import CustomTooltip from "./CustomTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannerSliderItem from "./BannerSliderItem";
import NewsTickerItem from "./NewsTickerItem";
import HomeGallerySliderItem from "./HomeGallerySliderItem";
import CustomFancyBox from "../fancybox/CustomFancyBox";
import { DEFAULT_BANNER } from "../../../config/constants";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${classes.btnNext} btnNext ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="angle-right" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${classes.btnPrev} btnPrev ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="angle-left" />
    </div>
  );
}

const NewsTicker = ({ announcements }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Row>
      <Col lg={11} className="my-auto">
        {announcements.length && (
          <Slider {...settings} className={classes["newsTicker"]}>
            {announcements.map((announcement, i) => (
              <NewsTickerItem
                key={`NewsTickerItem_${i}`}
                announcement={announcement}
              />
            ))}
          </Slider>
        )}
      </Col>
    </Row>
  );
};

export const NewsTickerSlider = NewsTicker;

const SiteBannerSlider = ({ bannerImages = [] }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  if (bannerImages.length === 0) {
    bannerImages = DEFAULT_BANNER;
  }
  return (
    <Slider
      {...settings}
      className="slideShow slideH500 imageTextBelow bg-secondary"
    >
      {bannerImages.map((bannerDetail, i) => (
        <BannerSliderItem
          key={`banner_${i}`}
          bannerImage={bannerDetail.bannerImage}
          title={bannerDetail.title}
          contents={bannerDetail.contents}
          ctaLabel={bannerDetail.ctaLabel}
          ctaLink={bannerDetail.ctaLink}
          type={bannerDetail.type}
          videoUrl={bannerDetail.videoUrl}
        />
      ))}
    </Slider>
  );
};

export const BannerSlider = SiteBannerSlider;

export const LoginSlider = ({ bannerImages = [] }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings} className="slideShow bg-secondary">
      {bannerImages.map((banner, i) => (
        <img
          src={banner.bannerImage}
          key={`loginSlider_${i}`}
          className="d-block img-fluid"
          alt=""
        />
      ))}
    </Slider>
  );
};

const SiteHomeGallerySlider = ({ galleryImages }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <CustomFancyBox options={{ infinite: false, autoFocus: false }}>
      <Slider {...settings} className="slideShow bg-secondary">
        {galleryImages.map((gallery, i) => (
          <HomeGallerySliderItem
            key={`HomeGallerySliderItem_${i}`}
            caption={gallery.caption}
            url={gallery.url}
          />
        ))}
      </Slider>
    </CustomFancyBox>
  );
};

export const HomeGallerySlider = SiteHomeGallerySlider;

export const ContentBlockSlider = (props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow: parseInt(props.column_number),
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      // {
      //   breakpoint: 991,
      //   settings: {
      //     slidesToShow: parseInt(props.column_number) === 1 ? 1 : 3,
      //     slidesToScroll: 1,
      //   },
      // },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: parseInt(props.column_number) === 1 ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider
      {...settings}
      className={`arrowOutside withSpaceSlider ${props.className}`}
    >
      {props.children}
    </Slider>
  );
};
