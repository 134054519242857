export const DOMAIN_NAME = "https://my.uowdubai.ac.ae";
export const ROUTE_BASENAME = "/";
export const SAML_LOGIN_URL = DOMAIN_NAME + "/saml2/uowdintranet/login";
export const SAML_LOGOUT_URL = DOMAIN_NAME + "/logout";

export const DEFAULT_BANNER = [
  {
    bannerImage: DOMAIN_NAME + "/images/banner.jpg",
    type: "image",
    url: DOMAIN_NAME + "/images/banner.jpg",
    caption:'',
  },
];