import { authActions } from "./authSlice";
import CommonAPI from "../lib/common";
import {
  LOGIN,
  CHANGE_PASSWORD,
  CREATE_ACCOUNT,
  SITE_CONFIG,
} from "../config/api-urls";

export const LoginHandler = (loginCredentials) => {
  return async (dispatch) => {
    try {
      const response = await CommonAPI(LOGIN, loginCredentials, "POST");
      if (response.data.status === "N") {
        dispatch(authActions.alert("User account is disabled. Please contact administrator."));
      } else {
        dispatch(authActions.login({ response: response }));
      }
    } catch (error) {
      if (error.response && error.response.data.message !== "") {
        dispatch(authActions.alert(error.response.data.message));
      } else {
        dispatch(authActions.alert("Something went wrong. Please try again."));
      }
    }
  };
};

export const getSiteConfig = () => {
  return async (dispatch) => {
    try {
      const response = await CommonAPI(SITE_CONFIG);
      return response;
    } catch (error) {
      dispatch(authActions.alert("Something went wrong. Please try again."));
    }
  };
};

export const changePasswordHandler = (newCredentials) => {
  return async (dispatch) => {
    try {
      await CommonAPI(CHANGE_PASSWORD, newCredentials, "POST");
      dispatch(authActions.alert(null));
    } catch (error) {
      dispatch(authActions.alert("Something went wrong. Please try again."));
    }
  };
};

export const createAccountHandler = (newCredentials) => {
  return async (dispatch) => {
    try {
      await CommonAPI(CREATE_ACCOUNT, newCredentials, "POST");
      dispatch(authActions.alert(null));
    } catch (error) {
      dispatch(authActions.alert("Something went wrong. Please try again."));
    }
  };
};

// export const fetchCartData = () => {
//   return async (dispatch) => {
//     const fetchData = async () => {
//       const response = await fetch(
//         "https://reduxcart-98744-default-rtdb.firebaseio.com/cart.json"
//       );

//       const responseData = await response.json();
//       return responseData;
//     };

//     try {
//       const cartData = await fetchData();

//       dispatch(
//         authActions.replaceCartData({
//           items: cartData.items || [],
//           totalQuantity: cartData.totalQuantity,
//         })
//       );
//     } catch (error) {
//       dispatch(
//         uiActions.showNotification({
//           status: "error",
//           title: "Error",
//           message: "Something went wrong.",
//         })
//       );
//     }
//   };
// };

// export const sendCartData = (cart) => {
//   return async (dispatch) => {
//     dispatch(
//       uiActions.showNotification({
//         status: "pending",
//         title: "Sending...",
//         message: "Sending cart data.",
//       })
//     );

//     const sendRequest = async () => {
//       const response = await fetch(
//         "https://reduxcart-98744-default-rtdb.firebaseio.com/cart.json",
//         {
//           method: "PUT",
//           body: JSON.stringify({
//             items: cart.items,
//             totalQuantity: cart.totalQuantity,
//           }),
//         }
//       );

//       if (!response.ok) {
//         dispatch(
//           uiActions.showNotification({
//             status: "error",
//             title: "Error",
//             message: "Sending cart data failed.",
//           })
//         );
//       }
//     };

//     try {
//       await sendRequest();
//       dispatch(
//         uiActions.showNotification({
//           status: "success",
//           title: "Success",
//           message: "Sent cart data successfully.",
//         })
//       );
//     } catch (error) {
//       dispatch(
//         uiActions.showNotification({
//           status: "error",
//           title: "Error",
//           message: "Sending cart data failed.",
//         })
//       );
//     }
//   };
// };
