import React, { Fragment, useEffect, useRef, useMemo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Breadcrumbs from "../../components/ui/Breadcrumbs";
import CommonAPI from "../../lib/common";
import { EVENTS_LIST } from "../../config/api-urls";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import useHttp from "../../hooks/use-http";
import { useDispatch } from "react-redux";
import { publicActions } from "../../store/publicSlice";
import { BannerSlider } from "../../components/ui/slider/SlickSlider";
import EventItem from "../../components/ui/EventItem";
import CustomPagination from "../../components/ui/CustomPagination";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Events() {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const keywordInputRef = useRef();

  useEffect(() => {
    dispatch(publicActions.toggleHamburger(false));
    window.scrollTo(0, 0);
  }, [dispatch]);

  const {
    sendRequest,
    status,
    data: loadedEvents,
    error,
  } = useHttp(CommonAPI, true);

  const url_params = queryParams.toString();

  useEffect(() => {
    sendRequest(EVENTS_LIST + "?" + url_params);
  }, [sendRequest, url_params]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const enteredKeyword = keywordInputRef.current.value;

    sendRequest(EVENTS_LIST + `?s=${enteredKeyword}`);
    history.push(`${match.url}?s=${enteredKeyword}`);
  };

  // var options = { year: "numeric", month: "long", day: "numeric" };
  // var today = new Date();

  //console.log(today.toLocaleDateString("en-US", options)); // Saturday, September 17, 2016

  return (
    <Fragment>
      <BannerSlider />
      <Breadcrumbs />
      <section
        className="bgCover"
      >
        <Container className="content mb-5">
          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <h2 className="bold text-uppercase mb-3 mb-sm-4">Events</h2>
            </Col>
            <Col sm={6} className="mb-3 mb-sm-0">
              <Form onSubmit={formSubmitHandler}>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <FloatingLabel label="Enter your keyword">
                      <Form.Control
                        type="text"
                        placeholder="Enter your keyword"
                        ref={keywordInputRef}
                      />
                    </FloatingLabel>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      size="lg"
                      variant="danger"
                      style={{ minWidth: "unset" }}
                    >
                      <FontAwesomeIcon icon="search" />
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>

          {status === "pending" && <LoadingSpinner />}
          {status === "completed" && console.log(loadedEvents.data)}
          {error && <p>{error}</p>}
          {status === "completed" && !error && loadedEvents.data.length > 0 && (
            <CustomPagination
              data={loadedEvents.data}
              RenderComponent={EventItem}
              pageLimit={5}
              dataLimit={loadedEvents.per_page}
              total={loadedEvents.total}
              additionalProps={{ showThumbnail: true }}
            />
          )}

          {status === "completed" && !error && loadedEvents.data.length < 1 && (
            <p>No records found.</p>
          )}
        </Container>
      </section>
    </Fragment>
  );
}

export default Events;
