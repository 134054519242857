import classes from './LoadingSpinner.module.css';

const LoadingSpinner = () => {
  return <div className={classes.spinner}></div>
}

export default LoadingSpinner;

export const PageLoader = () =>{
  return <div className={ classes.pageLoadingContainer}><div className={classes.spinner}></div></div>
}
