import React, { useState, Fragment, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

function CustomPagination({
  data,
  RenderComponent,
  pageLimit,
  dataLimit,
  additionalProps,
  total,
}) {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");

  const [pages] = useState(Math.ceil(total / dataLimit));
  const [currentPage, setCurrentPage] = useState(page ? page : 1);

  const history = useHistory();
  const match = useRouteMatch();

  //const href = new URL(`${match.url}`+location.search);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: "0px" });
  }, []);

  console.log(currentPage);
  useEffect(() => {
    if(currentPage!==1){
      queryParams.set("page", currentPage);
    }
    history.replace(`${match.url}?${queryParams.toString()}`);
  }, [currentPage]);

  function goToNextPage() {
    setCurrentPage((page) => parseInt(page) + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => parseInt(page) - 1);
  }

  function changePage(event) {
    setCurrentPage((prev) => event.target.textContent);
  }

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    //return new Array(pages).fill().map((_, idx) => start + idx + 1);
    //console.log(pages);
    return new Array(pages).fill().map((_, idx) => idx + 1);
  };

  return (
    <Fragment>
      {/* show the posts, 10 posts at a time */}
      {data.map((d, idx) => (
        <RenderComponent key={idx} data={d} additionalProps={additionalProps} />
      ))}

      {/* show the pagiantion
      it consists of next and previous buttons
      along with page numbers, in our case, 5 page
      numbers at a time
  */}
      {pages > 1 && (
        <div className="d-flex justify-content-center mt-5">
          <Pagination>
            {/* previous button */}

            {/* <button
          onClick={goToPreviousPage}
          className={`${classes.prev} ${currentPage === 1 ? `${classes.disabled}` : ""}`}
        >
          prev
        </button> */}
            <Pagination.Prev
              onClick={goToPreviousPage}
              disabled={currentPage.toString() === "1"}
            />
            {/* show page numbers */}
            {getPaginationGroup().map((item, index) => (
              <Pagination.Item
                key={index}
                onClick={
                  currentPage.toString() !== item.toString()
                    ? changePage
                    : undefined
                }
                active={currentPage.toString() === item.toString()}
              >
                {item}
              </Pagination.Item>
            ))}

            <Pagination.Next
              onClick={goToNextPage}
              disabled={currentPage.toString() === pages.toString()}
            />
            {/* next button */}
            {/* <button
          onClick={goToNextPage}
          className={`${classes.next} ${currentPage === pages ? `${classes.disabled}` : ""}`}
        >
          next
        </button> */}
          </Pagination>
        </div>
      )}
    </Fragment>
  );
}

export default CustomPagination;
