import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: localStorage.getItem("token") ? true : false,
  redirectModule: localStorage.getItem("user-info") ? JSON.parse(localStorage.getItem("user-info")).type.toLowerCase() : null,
  error: null,
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    login(state, action) {
      state.error = null;
      const data = action.payload.response;
      state.token = data.token;
      state.isAuthenticated = true;
      state.redirectModule=data.data.type.toLowerCase();
      localStorage.setItem("token", data.token);
      localStorage.setItem("user-name", data.data.name);
      localStorage.setItem("user-info", JSON.stringify(data.data));
    },
    logout(state) {
      state.error = null;
      state.isAuthenticated = false;
      state.token = null;
      state.redirectModule=null;
      localStorage.clear();
    },
    alert(state, action) {
      state.error = action.payload;
    },
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;
