import React from "react";
import { Link } from "react-router-dom";
import { DOMAIN_NAME, ROUTE_BASENAME } from "../../config/constants";

function SearchResultItem({ data }) {
  const slug=data.post_type==="services" ? data.service_slug : data.slug; 
  return (
    <div className="border-bottom dashed py-3">
      <h5 className="mt-0 mb-2">
        <Link to={`/${data.post_type}/${slug}`}>
          {data.title}{" "}
          <small className="text-muted mt-1">
            {`${DOMAIN_NAME}${ROUTE_BASENAME}/${data.post_type}/${slug}`}
          </small>
        </Link>
      </h5>
    
      <div
        dangerouslySetInnerHTML={{
          __html: data.content && data.content.length>500 ? data.content.substring(0, 500) + "..." : data.content,
        }}
      />
    </div>
  );
}

export default SearchResultItem;
