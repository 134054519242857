export const LOGIN = "users/login";
export const NOTICES_LIST = "notices/list";
export const NOTICES_SINGLE = "notices/show";
export const SERVICES_LIST = "services/lists";
export const SERVICES_SINGLE = "services";
export const SERVICES_DETAIL = "services/show";
export const FORMS_LIST = "forms/list";
export const DOCUMENTS_LIST = "documents";
export const WEB_FORM = "webforms/show";
export const SERVICE_BANNER = "banners/service";
export const BANNER = "banners/show";
export const EVENTS_LIST = "events/list";
export const EVENTS_LIST_PUBLIC = "events/list/public";
export const EVENTS_SINGLE = "events/show";
export const ANNOUNCEMENTS_LIST = "announcements/list";
export const PUBLIC_ANNOUNCEMENTS_LIST = "announcements/public";
export const PAGES_SINGLE = "pages/show";
export const STAFF_LIST = "staff/list";
export const DEPARTMENTS_LIST = "departments/list";
export const SEARCH_RESULTS = "search";
export const DOCUMENT_CATEGORIES = "document_categories";
export const DOCUMENT_FILTERS = "forms/filters";
export const SERVICE_REQUEST_API_URL = "service-request/send";
export const SERVICE_REQUEST_DEPARTMENT_API = "departments/service_request";
export const SITE_CONFIG="config/values";

export const CHANGE_PASSWORD="profile/change-password";
export const CREATE_ACCOUNT="profile/create-account";
export const USER_INFO="profile/ldap-status";