import React from "react";
import classes from "./NewsTicker.module.css";
import CustomTooltip from "./CustomTooltip";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function NewsTickerItem({ announcement }) {

  return (
    <Row
      className={`${classes.newsTickerItem} text-center text-md-start align-items-center px-3 px-lg-0`}
    >
      <Col md={8} lg={9}>
        <p>
         
            {announcement.content}
            {/* <a href={announcement.external_url} target="_blank" rel="noreferrer noopener"> Open Day:{" "}
          <span className="text-danger">
            Wednesday 5<sup>th</sup> January, 2022 from 4pm to 8pm{" "}
          </span>{" "}
          - Register now  </a>*/}
         
        </p>
      </Col>
      <Col md={4} lg={3} className="text-center pt-2 pt-md-0">
        {announcement.external_url &&
          announcement.external_url !== "#" &&
          announcement.external_url != null && (
            
              <a
                href={announcement.external_url}
                className={`${classes["btn"]} btn btn-danger`}
                target="_blank"
              >
                Read More
              </a>
              /* <CustomTooltip title="Read More">
            </CustomTooltip> */
          )}
      </Col>
    </Row>
  );
}

export default React.memo(NewsTickerItem);
